import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const WriteForMePage = () => (
  <Layout
    pageWrapperClassName="write-for-me-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Write My Essay For Me - Write My Essay"
      meta={[
        {
          name: 'description',
          content: 'Write My Essay At TakeAwayEssay.com 📝, our professional writers are trained to deliver top-notch essays, quickly and reliably. We provide guarantees of timely delivery, so you can trust us when you need a quality paper fast. Write My Essay For Me, and it will be done right!',
        },

        {
          property: 'og:title',
          content: 'Write My Essay for Me to Help Me with My Academic Score and Overcome My Issues | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'If you request “write my essay for me,” you should obligatorily review this page because it describes the main benefits and guarantees offered by our professional writing company. Place an instant order straight away and let professional writers solve your academic problems.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/write-for-me.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Get your essay written
        <br className="first-screen-section__title__br" />
        {' '}
        in no time
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Hire a professional to help you
        <br className="first-screen-section__heading__br" />
        {' '}
        deal with your assignments
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Write my essay: <br /> write my essay for me
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            “What essay writing agency can do my homework for me? I need professional help.” Such
            online requests are no rarity today. Many students have problems managing their
            assignments appropriately and lose multiple grades. They are honest with their inner
            self and confess that some assignments cannot be completed on their own. Accordingly,
            they ask online helpers “write my essay.” There are multiple academic writing websites
            on the Internet, which eagerly offer their assistance. However, we easily outweigh their
            advantages because our services are more beneficial.
          </p>
          <p>
            <strong>
              We are called TakeAwayEssay.com and thousands of students prefer us for outstanding
              quality, great speed, and adequate prices we propose. Our professional writers easily
              tackle assignments with the most difficult requirements and always meet the
              expectations of our clients.
            </strong>{' '}
            If you wonder “who will write my essay for me” turn to us and we promise to help you
            solve all your complications in the best manner.
          </p>
          <h2 className="h3">Do my essay for me quickly and originally</h2>
          <p>
            Every academic assignment has strict requirements, which must be obligatorily fulfilled.
            When students ask “write my essay online,” they hope to meet important targets. These
            are the submission of the assignment on time and creating original content. We guarantee
            both prerogatives.
          </p>
          <p>
            Our skilled specialists know everything about the speed of writing. They possess great
            writing skills and continuously develop them. They are very flexible and easily adapt to
            different demands. Thus, our writers easily select the most efficient technique to be on
            time. They complete any type of essay and other assignments without delays.
          </p>
          <p>
            We also pay attention to the authenticity of the texts we release. We employ skilled
            writers who easily cover different topics in all academic disciplines. Specially
            assigned editors verify every text using a trustworthy plagiarism-checking software. It
            scans your text, surfs the Internet and seeks matches. If there are any, we’ll rewrite
            them to make your assignments original. Accordingly, if you request “pay someone to
            write my essay” on our platform you avoid any form of plagiarism. Your texts are always
            100% authentic.
          </p>
          <h2 className="h3">Can you write my essay online to help me meet academic requirements?</h2>
          <p>
            Undoubtedly, all the students who leave requests “write my essays” want to receive the
            assistance of the highest quality. Our experts are certified, skilled, and experienced.
            Each writer writes several entry tests and passes a job interview to determine his or
            her competence. We accept only the top writers. Accordingly, they match the highest
            academic standards. You’ll enjoy the assistance of native speakers and experts whose
            English is their second language.
          </p>
          <p>You’re free to order any paper that is necessary. We compose:</p>
          <ul className="dot-list">
            <li>Essays</li>
            <li>Dissertations</li>
            <li>PowerPoint presentations</li>
            <li>Case studies</li>
            <li>Laboratory reports</li>
            <li>Book/movie/literature reviews</li>
            <li>Personal statements, etc.</li>
          </ul>
          <p>
            TakeAwayEssay.com is the most beneficial website where you can request “do my essay” and
            receive orders of the highest quality. Make an instant order straight away and solve all
            your complications.
          </p>
          <h2 className="h3">I want to pay someone to write my essays on reasonable terms</h2>
          <p>
            Can you do my essay for me and will I be able to buy it? This is a frequently asked
            question. Many students are terribly afraid to overpay. Fortunately, this is not a story
            of our website. We offer a relatively cheap price policy to make our assistance
            affordable.
          </p>
          <p>
            When you request “do my essays,” place an order filling out the application form.
            Mention the most important details concerning your assignment. These are its quality,
            type, deadline, length, etc. Each point has its price and directly affects the total
            cost. For example, you have a choice among three different quality levels of writing.
            These are a high school, college or university. The more complex the level is the more
            fees will be charged. It’s a fair system, which helps to set the price you require.
          </p>
          <h2 className="h3">Do you provide some additional benefits?</h2>
          <p>
            Can someone write my essay and provide all the necessary guarantees and advantages? This
            is another frequently asked question among students. They need certain conditions, which
            ensure their safety and comfort. Our custom writing service is ready to offer them.
            You’ll receive the following benefits:
          </p>
          <ul className="dot-list">
            <li>
              Absolute confidentiality. Our writing platform secures the full confidentiality of
              your personal information by applying its privacy policy and the GDPR. An effective
              anti-virus program and cyber-security software perfectly deflect all kinds of
              cyber-attacks.
            </li>
            <li>
              Monetary compensation. In case you collaborate with us, your earnings are ensured. If
              we agree on your terms but fail any of them, you can apply for a refund.
            </li>
            <li>
              24/7 customers support. We are always open and are ready to accept the most urgent
              orders. A team of experienced consultants is at your service to provide detailed
              clarifications and answers.
            </li>
          </ul>
          <p>
            These are pretty important benefits, aren’t they? We’re able to cope with any piece of
            writing quickly and effectively. Therefore, don’t delay and place an order straight
            away! Tell your friend if he or she is in need. We’ll help everyone.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Get assistance">
      Short on time?
      <br />
      {' '}
      No worries, we are here for you!
    </CTA>

  </Layout>
);

export default WriteForMePage;
